import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/fonts/fonts.css'
import scroll from 'vue-seamless-scroll'
// 引入加载动画
import '@/style/loading.less'
// 引入自定义指令
import Directives from '@/directives'
import { MessageBox, Message, Loading, Dialog, RadioGroup, Radio, Checkbox, CheckboxGroup, Cascader, Progress, Descriptions } from 'element-ui'
import '@/plugins/element-ui'
Vue.use(scroll)
Vue.use(Directives).use(Loading.directive).use(Dialog).use(RadioGroup).use(Radio).use(Checkbox).use(CheckboxGroup).use(Cascader).use(Progress).use(Descriptions)
Vue.prototype.$msgBox = MessageBox
Vue.prototype.$message = Message
Vue.prototype.$loading = Loading.service
Vue.prototype.$confirm = MessageBox.confirm

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
