import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
  // 布局目录
  {
    path: '/',
    redirect: '/home',
    component: () => import('@/layout/index.vue'),
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/HomeView/index.vue'),
        meta: {
          isLogin: false
        }
      },
      {
        path: '/partner',
        name: 'partner',
        component: () => import('@/views/HomeView/Detail.vue'),
        meta: {
          isLogin: false
        }
      },
      {
        path: 'products',
        redirect: '/products/list',
        name: 'products',
        component: () => import('../views/ProductsView'),
        children: [
          {
            path: '/products/details',
            component: () => import('../views/ProductsView/SeeDetails.vue')
          },
          {
            path: '/products/list',
            name: 'productList',
            component: () => import('../views/ProductsView/List.vue')
          },
          {
            path: '/products/application',
            component: () =>
              import('../views/ProductsView/SubmitApplication.vue')
          },
          {
            path: '/products/demand',
            component: () => import('../views/ProductsView/UserDemand.vue')
          }
        ]
      },
      {
        path: 'zone',
        redirect: '/zone/list',
        name: 'zone',
        component: () => import('../views/ZoneView'),
        children: [
          {
            path: '/zone/detail',
            name: 'zoneDetail',
            component: () => import('../views/ZoneView/detail.vue')
          },
          {
            path: '/zone/list',
            component: () => import('../views/ZoneView/List.vue')
          },
          {
            path: '/zone/img',
            name: 'zoneImg',
            component: () => import('../views/ZoneView/img.vue')
          },
          {
            path: '/zone/vedioPage',
            name: 'zoneVedioPage',
            component: () => import('../views/ZoneView/vedioPage.vue')
          }
        ]
      },
      {
        path: 'news',
        name: 'news',
        component: () => import('../views/NewsView'),
        redirect: '/news/list',
        children: [
          {
            path: '/news/list',
            component: () => import('../views/NewsView/List.vue'),
            meta: {
              isLogin: false,
              // 隐藏导航栏背景
              hideBg: true
            }
          },
          {
            path: '/news/detail',
            component: () => import('../views/NewsView/Detail.vue'),
            meta: {
              isLogin: false
            }
          },
          {
            path: '/news/threeLists',
            component: () => import('../views/NewsView/threeLists.vue'),
            meta: {
              isLogin: false
            }
          }
        ]
      },
      {
        path: 'case',
        name: 'case',
        component: () => import('../views/CaseView'),
        meta: {
          isLogin: false
        }
      },
      {
        path: 'mine',
        name: 'mine',
        component: () => import('../views/MineView'),
        children: [
          {
            // 用户中心
            path: '/mine/userCenter',
            name: 'userCenter',
            redirect: '/mine/userCenter/account',
            component: () => import('../views/MineView/UserCenter/index.vue'),
            meta: {
              isLogin: true
            },
            children: [
              {
                // 账号信息页
                path: '/mine/userCenter/account',
                component: () =>
                  import('../views/MineView/UserCenter/AccountInfo.vue'),
                meta: {
                  isLogin: true
                }
              },
              {
                // 重新认证页
                path: '/mine/userCenter/recertification',
                component: () =>
                  import('@/views/MineView/UserCenter/Recertification'),
                meta: {
                  isLogin: true
                }
              },
              {
                // 金融服务页
                path: '/mine/userCenter/services',
                component: () =>
                  import('@/views/MineView/UserCenter/FinancialServices'),
                meta: {
                  isLogin: true
                }
              },
              {
                // 贷款申请单
                path: '/mine/userCenter/loanAoolication',
                name: 'loanAoolication',
                component: () =>
                  import('@/views/MineView/UserCenter/LoanApplicationForm'),
                meta: {
                  isLogin: true
                }
              },
              {
                // 信用服务
                path: '/mine/userCenter/creditServices',
                component: () =>
                  import('@/views/MineView/UserCenter/CreditServices'),
                meta: {
                  isLogin: true
                }
              },
              {
                // 信用报告
                path: '/mine/userCenter/creditReport',
                name: 'creditReport',
                component: () =>
                  import('@/views/MineView/UserCenter/CreditReport'),
                meta: {
                  isLogin: true
                }
              },
              {
                // 用户反馈
                path: '/mine/userCenter/feedback',
                component: () =>
                  import('@/views/MineView/UserCenter/UserFeedback'),
                meta: {
                  isLogin: true
                }
              },
              {
                // 系统信息
                path: '/mine/userCenter/systemNews',
                component: () =>
                  import('@/views/MineView/UserCenter/SystemInfo'),
                meta: {
                  isLogin: true
                }
              }
            ]
          },
          {
            path: '/mine/changePassword',
            component: () => import('@/views/MineView/ChangePassword'),
            meta: {
              isLogin: true
            }
          },
          {
            path: '/mine/changePhoneNumber',
            component: () => import('@/views/MineView/ChangePhoneNumber'),
            meta: {
              isLogin: true
            }
          },
          {
            // 注册用户页
            path: '/mine/register',
            name: 'registerUser',
            component: () => import('../views/MineView/Register.vue'),
            meta: {
              // 隐藏注册文本
              hideRegisterText: true,
              isLogin: false
            }
          },
          {
            // 找回密码页
            path: '/mine/recover',
            name: 'recoverPassword',
            component: () => import('../views/MineView/Recover.vue'),
            meta: {
              isLogin: true
            }
          }
        ]
      }
    ]
  },
  // 首页风控报告
  {
    path: '/riskReport',
    name: 'riskReport',
    component: () => import('../views/HomeView/riskReport.vue')
  },
  {
    path: '/zone',
    redirect: '/zone/list',
    name: 'zone',
    component: () => import('../views/ZoneView'),
    children: [
      {
        path: 'detail',
        name: 'zoneDetail',
        component: () => import('../views/ZoneView/detail.vue')
      },
      {
        path: 'list',
        component: () => import('../views/ZoneView/List.vue')
      },
      {
        path: 'img',
        name: 'zoneImg',
        component: () => import('../views/ZoneView/img.vue')
      },
      {
        path: 'vedioPage',
        name: 'zoneVedioPage',
        component: () => import('../views/ZoneView/vedioPage.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView'),
    meta: {
      isLogin: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // base: '/tp/public/dist/',
  routes
})
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  next()
})
export default router
