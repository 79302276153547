// 平滑滚入自定义指令
const DISTANCE = 100
const DURATION = 1000

// 映射el与动画的对应关系，方便在视口监听函数中调用。不推荐使用entries.target.getAnimations()[]...
// 应为考虑到通用性，使用entries.target.getAnimations()不能防止用户自己添加的有动画，使该动画的列表位置不确定
// Weakmap防止内存泄漏，以便动画消失后，不能进行垃圾回收
const animationMap = new WeakMap()
// 监听元素是否跟某个元素重叠，默认情况下监听跟视口是否有重叠，有重叠会执行回调
const ob = new IntersectionObserver((entries) => {
  // console.log(entries)
  for (const entry of entries) {
    if (entry.isIntersecting) {
      const animation = animationMap.get(entry.target)
      animation.play()
      // 出现一次后取消对该元素的监听
      ob.unobserve(entry.target)
    }
  }
})

function isBelowViewport (el) {
  const rect = el.getBoundingClientRect()
  return rect.top > window.innerHeight
}
const slidenIn = {
  inserted: function (el) {
    if (!isBelowViewport(el)) {
      return
    }
    // 考虑到通用性，不单独设置style属性，防止把原本的属性覆盖
    const animation = el.animate(
      [
        {
          transform: `translateY(${DISTANCE}px)`,
          opacity: 0.5
        },
        {
          transform: 'translateY(0vw)',
          opacity: 1
        }
      ],
      {
        duration: DURATION,
        easing: 'ease'
      }
    )
    animation.pause()
    animationMap.set(el, animation)
    ob.observe(el)
  },
  unbind: function (el) {
    ob.unobserve(el)
  }
}

export default slidenIn
