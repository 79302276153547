// 更改用户状态信息
export const userStatus = (state, user) => {
  // 判断用户是否存在
  if (user === null || user === 'null') {
    // 登出时清空sessionStroage里面的参数
    sessionStorage.setItem('user', null)
    sessionStorage.setItem('userToken', '')
    state.currentUser = null
    state.isLogin = false
    // state.assign = ''
  } else if (user != null) {
    state.name = JSON.parse(user).name
    state.phone = JSON.parse(user).phone
    state.role = JSON.parse(user).role
    state.isLogin = true
    state.status = JSON.parse(user).status
    state.isCertified = JSON.parse(user).isCertified
  }
}
// 更改token
export const setToken = (state, token) => {
  if (token != null) {
    state.token = token
  } else {
    state.token = ''
  }
}

export const setReportFlag = (state, reportFlag) => {
  if (reportFlag != null) {
    state.reportFlag = reportFlag
  } else {
    state.reportFlag = false
  }
}
