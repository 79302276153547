import Vue from 'vue'
import Vuex from 'vuex'
import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from './actions'

Vue.use(Vuex)

const store = new Vuex.Store({
  // 存储用户登录信息的一个状态
  state: {
    name: null, // 当前用户名
    role: null, // 用户角色 1=个人 2=企业
    phone: null,
    isLogin: false, // 登录状态
    status: null, // 认证状态 1未认证 2已认证
    isCertified: null,
    token: '', // 用户登录成功后持有的token
    reportFlag: false
  },
  // 关联着其他三个目录，目的松耦合
  getters,
  mutations,
  actions
})
export default store
