<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { getUserInfo } from '@/api/index'
import { decrypt } from '@/utils/crypto'
export default {
  created () {
    const tokenStr = window.location && window.location.search
    // 从别处跳转来，并且已经在别的平台登陆过
    if (tokenStr.indexOf('userToken') > -1) {
      const tmp = tokenStr.split('userToken=')[1]
      console.log('跳转携带token---', tmp)
      sessionStorage.setItem('userToken', tmp)
      this.$store.dispatch('setToken', tmp)
      getUserInfo().then(respon => {
        if (+respon.code === 200) {
          respon.data.username = decrypt(respon.data.username)
          respon.data.poa = decrypt(respon.data.poa)
          respon.data.phone = decrypt(respon.data.phone)
          respon.data.cardid = decrypt(respon.data.cardid)
          const { realname, status, role, phone, isCertified, company } = respon.data
          const userInfo = {
            isLogin: true, // 是否登录
            name: realname || company,
            phone, // 手机号
            role, // 1=个人 2=企业
            status, // 认证状态 1未认证 2已认证
            isCertified // 2-认证失败 3-已认证
          }
          // 将用户信息放入sessionStorage中
          sessionStorage.setItem('user', JSON.stringify(userInfo))
          // 将用户名放入vuex中
          this.$store.dispatch('setUser', JSON.stringify(userInfo))
          this.$message.success('登录成功')
          const pathStr = window.location.pathname
          switch (pathStr) {
            case '/zone/img':
              this.$router.push(pathStr + '?type=1')
              break
            case '/zone/detail':
              this.$router.push(pathStr + '?type=2')
              break
            default:
              this.$router.push(pathStr)
              break
          }
        } else {
          this.$message.error(respon.msg)
        }
      })
    } else {
      console.log('非跳转-')
      this.isLogin()
    }
  },
  methods: {
    // 判断是否已经登录状态
    isLogin () {
      const user = sessionStorage.getItem('user')
      // 判断sessionStorage中是否有登录信息
      if (user != null && sessionStorage.getItem('userToken')) {
        this.$store.commit('setReportFlag', false)
        // 存在登录信息就从sessionStorage中提取状态再传值给vuex中
        console.log('存在登录信息--')
        this.$store.commit('userStatus', user)
      } else {
        // 登录不成功就将vuex中的state清空
        console.log('登录不成功--')
        this.$store.commit('userStatus', null)
      }
      // 返回登录状态islogin
      return this.$store.getters.isLogin
    },
    // 通过登录状态来判断用户是否登录执行相关的操作
    ver () {
      if (this.$store.state.isLogin) {
        console.log('已登录')
      } else {
        // 如果没有登录就返回登录界面
        console.log('未登录')
      }
    }
  }
}
</script>

<style lang="less">
@import url("@/assets/css/iconfont.css");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  user-select: none;
}
.homepage-map-tootip {
  &-content {
    font-size: 1vw;
    background: #ffffff;
    box-shadow: 0 0.0666vw 0.5333vw 0 rgba(0, 38, 90, 0.1);
    padding: 0.5vw 1vw;
    border-radius: 0.6666vw;
  }

  &-title {
    padding: 0 0 0.5vw;
    color: #2882ff;
    border-bottom: 0.0666vw solid #e4e4e4;
  }

  &-sts {
    padding: 0.5vw 0 0;
  }

  &-progress-bg {
    margin: 0.4vw 0;
    width: 100%;
    height: 0.5333vw;
    background: rgba(40, 130, 255, 0.2);
    border-radius: 0.2666vw;
    position: relative;
  }

  &-progress-line {
    position: absolute;
    top: 0;
    left: 0;
    height: 0.5333vw;
    width: 2vw;
    background: linear-gradient(270deg, #2882FF 0%, rgba(40, 130, 255, 0) 100%);
    border-radius: 0.2666vw;
  }

  &-progress-spot {
    position: absolute;
    top: -0.1333vw;
    width: 0.8vw;
    height: 0.8vw;
    background: #2580ff;
    box-shadow: 0vw 0vw 0.7333vw 0vw #6c9bff;
    border-radius: 50%;
  }
}

.item-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.6vw;

  .left {
    font-size: 0.8vw;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }

  .right {
    font-size: 0.8vw;
    font-family: PingFangSC-Medium, PingFang SC,serif;
    font-weight: 500;
    color: #333333;
  }
}
/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'iconfont';  /* Project id 4508026 */
  src: url('//at.alicdn.com/t/c/font_4508026_m0llhph85dj.woff2?t=1713161974398') format('woff2'),
        url('//at.alicdn.com/t/c/font_4508026_m0llhph85dj.woff?t=1713161974398') format('woff'),
        url('//at.alicdn.com/t/c/font_4508026_m0llhph85dj.ttf?t=1713161974398') format('truetype');
}
</style>
