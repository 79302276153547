import SlideIn from './data/vSlideIn'
const directives = {
  SlideIn
}

export default {
  install (Vue) {
    Object.keys(directives).forEach((item) => {
      Vue.directive(item, directives[item])
    })
  }
}
