import { JSEncrypt } from 'jsencrypt'

const puk = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDXn1c2ZLYwah94Eh83fOFMGqQ5QbiQYQuvAEONfooJ/mpWomM5Il+wwgkD6kUpQgGl1M1179vUyOHKX8CfkVCkJgXgXWA5l2/p53c5cXYkTqjIM+7yY/jAQRF3xOqlCIGve603woxoOkRv4eI0YaPkBh1e3LIC6xom5jZzMC7SewIDAQAB'
const prk = 'MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBANefVzZktjBqH3gSHzd84UwapDlBuJBhC68AQ41+ign+alaiYzkiX7DCCQPqRSlCAaXUzXXv29TI4cpfwJ+RUKQmBeBdYDmXb+nndzlxdiROqMgz7vJj+MBBEXfE6qUIga97rTfCjGg6RG/h4jRho+QGHV7csgLrGibmNnMwLtJ7AgMBAAECgYEAnAaaNir0Rt5T/S+n1SARa1+P3Dq5UghXSv/24elh+89UpXIGOK6t7ZrKqUGZYDx6MsRlFGVHfpcKA8X7/btKTNl9Tw/b7Mo47BPYjcU0Mu6XGJnu49ftL7P6WJMiONbXi1FKCAzuQlqYh0mtKckQOW306mULpG0GvFv90cBhSUkCQQDvfrpEoX3L5GrIVXdKRjqNWaITdYUXVahP7ibXaVlM5I4huejhGWP7Qr8zyiE5P1sW5I6muDwroFXr2RwkOqplAkEA5ntxRt4YAFoVwqQDpUjR3RwhGHXw3wwtZ4axx7Uxy0cBkqrtSU84LCP0DH2IL0aAfZvpeuqNAqUj8HrAPaZLXwJAR7/dqgX/m//4hcAlFtlnXUWHKvFASDdfT2L3u/1RxZmLg0shG57xtyrOAYQC5BW5AmjC2+vCCwfj9d57cdcpiQJAbDZ0/uLzveaN5O8Azy+baQb1+vgU+YRMJRk2ix9lm51g/a3G7u6E3ySaRusBb8DYPyFew9kwD5fdgeEANXHIXQJANeUCb8gbRyAU2lw81J9lLh10VANzL6NKhy5Zfz4NojrvHb8Fkzzc7BNEwmPS+pF1kqzqGUxz7bBz259RBhQ+fw=='
// rsa加密
export function encrypt (data) {
  // 新建JSEncrypt对象
  const encryptor = new JSEncrypt()
  // 设置公钥
  encryptor.setPublicKey(puk) // key为公钥
  // 加密数据
  return encryptor.encrypt(data) // data就是需要加密的密码
}
export function decrypt (encryptedData) {
  const decryptor = new JSEncrypt()
  decryptor.setPrivateKey(prk)
  const res = decryptor.decrypt(encryptedData)
  return res === false ? '' : res
}
