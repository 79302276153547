import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css'
import {
  RadioButton,
  Menu,
  MenuItem,
  Carousel,
  CarouselItem,
  Select,
  Button,
  Option,
  Input,
  Pagination,
  Breadcrumb,
  BreadcrumbItem,
  Checkbox,
  Form,
  FormItem,
  DatePicker,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Steps,
  Step,
  Upload,
  Row,
  Col,
  Table,
  TableColumn
} from 'element-ui'

const ALL = [
  RadioButton,
  Menu,
  MenuItem,
  Carousel,
  CarouselItem,
  Select,
  Button,
  Option,
  Input,
  Pagination,
  Breadcrumb,
  BreadcrumbItem,
  Checkbox,
  Form,
  FormItem,
  DatePicker,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Steps,
  Step,
  Upload,
  Row,
  Col,
  Table,
  TableColumn
]
ALL.forEach((item) => Vue.use(item))
